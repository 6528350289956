/* eslint-disable import/first */
import BugSnag from '@bugsnag/js';
import BugSnagPluginReact from '@bugsnag/plugin-react';
import platform from 'platform';
import { isChunkLoadingError, isOldBrowser } from 'Utils/browser';

const showError = (html: string) => {
	const div = document.createElement('div');
	div.innerHTML = html;
	div.style.display = 'flex';
	div.style.flexDirection = 'column';
	div.style.alignItems = 'center';
	div.style.justifyContent = 'center';
	div.style.height = '100vh';
	div.style.padding = '0 2rem';
	div.style.textAlign = 'center';
	document.body.appendChild(div);
};

BugSnag.start({
	apiKey: import.meta.env.VITE_BUGSNAG,
	plugins: [new BugSnagPluginReact()],
	releaseStage: import.meta.env.VITE_ENV,
	onError: event => {
		const status = event.originalError?.response?.status;

		return !event.getMetadata('unhandledrejection handler') && !(status === 401 || status === 403);
	},
	enabledReleaseStages: ['production', 'staging'],
});

if (isOldBrowser(platform.version, platform.name)) {
	showError(`<h2>Old browser version</h2><p>Please, update your browser version to view this page</p>`);
} else {
	import('./app').catch((error: Error) => {
		const chunkLoadError = isChunkLoadingError(error);

		if (!chunkLoadError) {
			throw error;
		}

		const chunkErrorsCountString = sessionStorage.getItem('chunkErrorsCount');
		const chunkErrorsCount = +(chunkErrorsCountString ?? 0);

		if (chunkErrorsCount < 3) {
			sessionStorage.setItem('chunkErrorsCount', `${chunkErrorsCount + 1}`);
			window.location.reload();
		}
		showError(`<h2>Your browser is trying to access an older version of ScoreApp.
				<br />Please refresh your browser. If the problem persists try clearing your cache.</h2>`);
	});
}
