import { compare } from 'compare-versions';

import { BROWSERS_VERSION } from 'Constants/general';

export const isOldBrowser = (version, name) => {
	if (!version) return false;

	const browserVersion = version.replace(/,/g, '.');

	return name === 'IE' || (!!BROWSERS_VERSION[name] && compare(browserVersion, BROWSERS_VERSION[name], '<'));
};

export const isChunkLoadingError = (error?: Error) => {
	const chunkRegexp = /ChunkLoadError/gi;
	const moduleRegexp =
		// eslint-disable-next-line max-len
		/(Importing a module script failed)|(Failed to fetch dynamically imported module)|(Unable to preload CSS for \/assets)/i;

	return import.meta.env.PROD && !!error && (chunkRegexp.test(error.message) || moduleRegexp.test(error.message));
};
